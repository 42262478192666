import React from 'react';
import bg from "../../Assets/img/bg.jpg";
import blogImg from './../../Assets/img/blog.jpg'
import {Helmet} from "react-helmet";

const blogschemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.megatrashremoval.com/blog"
    },
    "headline": "BLOG",
    "description": "Stay informed with Mega Trash Removal's blog. Your go-to resource for tips, news, and insights on trash removal. Let's clean LA together!",
    "image": "",  
    "author": {
      "@type": "Organization",
      "name": "Mega Trash Removal"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Mega Trash Removal",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.megatrashremoval.com/static/media/logo.9f2a0fa14bef42e9fee8.png"
      }
    },
    "datePublished": ""
  };

const Blogs = () => {

    const blogs = [
        {
            img: blogImg,
            link: '/residential-trash-removal',
            date: '24 February 2020',
            title: 'Residential Trash Removal',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
        {
            img: blogImg,
            link: '/yard-clean-up',
            date: '24 February 2020',
            title: 'Yards Clean Up',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
        {
            img: blogImg,
            link: '/general-clean-up',
            date: '24 February 2020',
            title: 'General Clean Up',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
        {
            img: blogImg,
            link: '/hills-clean-up',
            date: '24 February 2020',
            title: 'Hills Clean Up',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
        {
            img: blogImg,
            link: '/construction-trash-removal',
            date: '24 February 2020',
            title: 'Construction Trash Removal',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
        {
            img: blogImg,
            link: '/commercial-trash-removal',
            date: '24 February 2020',
            title: 'Commercial Trash Removal',
            text: 'Mega Season Removal does Residential Trash Removal services around Los Angeles County & San Fernando Valley.'
        },
    ]

    return (
        <>
            <Helmet>
                <title>Blog | Mega Trash Removal: Your Skilled Trash Removal</title>
                <meta name="description" content="Stay informed with Mega Trash Removal's blog. Your go-to resource for tips, news, and insights on trash removal. Let's clean LA together!" />
                <link rel="canonical" href="https://www.megatrashremoval.com/blog" />
                <script type="application/ld+json">
                    {JSON.stringify(blogschemaMarkup)}
                </script>
                <meta property="og:url" content="https://www.megatrashremoval.com/blog"/>
                <meta property="og:site_name" content="#site_name"/>
                <meta property="og:title" content="Blog | Mega Trash Removal: Your Skilled Trash Removal"/>
                <meta property="og:description" content="Stay informed with Mega Trash Removal's blog. Your go-to resource for tips, news, and insights on trash removal. Let's clean LA together!"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="Blog | Mega Trash Removal: Your Skilled Trash Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="314"/>
                <meta property="og:image:alt" content="Blog | Mega Trash Removal: Your Skilled Trash Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="400"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:image:alt" content="Blog | Mega Trash Removal: Your Skilled Trash Removal"/>

                <meta name="twitter:card" content="sumary"/>
                <meta name="twitter:site" content="#site_twitter"/>
                <meta name="twitter:creator" content="#creator_twitter"/>

            </Helmet>
            <section>
                <div className="container-fluid g-0">
                    <div className="page-banner">
                        <img src={bg} className="img-fluid" alt="About Us"/>
                        <div className="overly"/>
                        <div className="content">
                            <h1 className="display-1 text-white text-uppercase">BLOG</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 py-5"><h2
                            className="text-center text-uppercase display-4">NEWS BLOG</h2><h3
                            className="text-center text-uppercase display-6 theme-color">Latest news from our blog</h3>
                        </div>
                    </div>
                    <div className="row">
                        {blogs.map((blog, index)=>(
                            <div className="col-md-4 my-5">
                                <div className="home-blog">
                                    <img src={blog.img} className="img-thumbnail" alt="blog"/>
                                    <p className="my-2"><small>{blog.date}</small></p>
                                    <a href="/residential-trash-removal">
                                        <h4>{blog.title}</h4>
                                    </a>
                                    <p>{blog.text}
                                        <a href="/residential-trash-removal">
                                            <button className="btn btn-sm btn-outline-success ms-2">Read More</button>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>
        </>

    );
};

export default Blogs;
