import React from 'react';
import Img from './../../Assets/img/about.jpg'

const HomeAbout = () => {
    return (
        <section>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12"><h2 className="text-center text-uppercase display-4">WELCOME TO MEGA
                        TRASH REMOVAL</h2><h3 className="text-center text-uppercase display-6 theme-color">About Mega
                        Trash Removal</h3></div>
                </div>
                <div className="row section-gap">
                    <div className="col-md-6">
                        <img src={Img} className="img-thumbnail" alt="About US"/>
                    </div>
                    <div className="col-md-6"><h6 className="text-center text-uppercase">WELCOME TO MEGA TRASH
                        REMOVAL</h6><h5 className="text-center text-uppercase theme-color">About Mega Trash Removal</h5>
                        <p className="lead py-5">Awesome! the best company that lets you clean and remove trash and
                            rubbish anywhere LA county you want.</p>
                        <div className="row">
                            <div className="col-4"><h2 className="theme-color display-3">13</h2><p
                                className="lead">YEARS OF EXPERIENCED</p></div>
                            <div className="col-4"><h2 className="theme-color display-3">2,342</h2><p
                                className="lead">HAPPY CUSTOMERS</p></div>
                            <div className="col-4"><h2 className="theme-color display-3">30</h2><p
                                className="lead">AWARD WINNING </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeAbout;
