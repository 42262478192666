import React from 'react';
import bg from './../../Assets/img/bg.jpg'
import about from './../../Assets/img/about.jpg'
import {Helmet} from "react-helmet";

const aboutschemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.megatrashremoval.com/about"
    },
    "headline": "ABOUT",
    "description": "Discover Mega Trash Removal: LA's top choice for trash cleanup. 13 years of excellence, 2,342 happy customers, and 30 awards. Call us today!",
    "image":"",  
    "author": {
      "@type": "Organization",
      "name": "Mega Trash Removal"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Mega Trash Removal",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.megatrashremoval.com/static/media/logo.9f2a0fa14bef42e9fee8.png"
      }
    },
    "datePublished": ""
  };

const About = () => {
    return (
        <>
            <Helmet>
                <title>Mega Trash Removal: Skilled Junk Removal Providers</title>
                
                <meta name="description" content="Discover Mega Trash Removal: LA's top choice for trash cleanup. 13 years of excellence, 2,342 happy customers, and 30 awards. Call us today!"/>

                <link rel="canonical" href="https://www.megatrashremoval.com/about" />
                <script type="application/ld+json">
                    {JSON.stringify(aboutschemaMarkup)}
                </script>
                <meta property="og:url" content="https://www.megatrashremoval.com/about"/>
                <meta property="og:site_name" content="#site_name"/>
                <meta property="og:title" content="About Mega Trash Removal: Your Skilled Junk Removal"/>
                <meta property="og:description" content="Discover Mega Trash Removal: LA's top choice for trash cleanup. 13 years of excellence, 2,342 happy customers, and 30 awards. Call us today!"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="About Mega Trash Removal: Your Skilled Junk Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="314"/>
                <meta property="og:image:alt" content="About Mega Trash Removal: Your Skilled Junk Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="400"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:image:alt" content="About Mega Trash Removal: Your Skilled Junk Removal"/>

                <meta name="twitter:card" content="sumary"/>
                <meta name="twitter:site" content="#site_twitter"/>
                <meta name="twitter:creator" content="#creator_twitter"/>


            </Helmet>
            <section>
                <div className="container-fluid g-0">
                    <div className="page-banner">
                        <img src={bg} className="img-fluid" alt="About Us"/>
                        <div className="overly"/>
                        <div className="content">
                            <h1 className="display-1 text-white text-uppercase">ABOUT</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row section-gap">
                        <div className="col-md-6">
                            <img src={about} className="img-thumbnail" alt="About US"/>
                        </div>
                        <div className="col-md-6">
                            <h6 className="text-center text-uppercase">WELCOME TO MEGA TRASH REMOVAL</h6>
                            <h5 className="text-center text-uppercase theme-color">About Mega Trash Removal</h5>
                            <p className="lead py-5">Awesome! the best company that lets you clean and remove trash and
                                rubbish anywhere LA county you want.
                            </p>
                            <div className="row">
                                <div className="col-4"><h2 className="theme-color display-3">13</h2><p
                                    className="lead">YEARS OF EXPERIENCED</p></div>
                                <div className="col-4"><h2 className="theme-color display-3">2,342</h2><p
                                    className="lead">HAPPY CUSTOMERS</p></div>
                                <div className="col-4"><h2 className="theme-color display-3">30</h2><p
                                    className="lead">AWARD WINNING </p></div>
                            </div>
                        </div>
                    </div>
                    <div className="row section-gap justify-content-center">
                        <div className="col-md-12 text-start"><h4 className="text-uppercase mb-3">About Us</h4><p
                            className="lead">In fact, our employees live in the neighborhoods where we provide service, Call
                            us at (818) 773-7599, so you can count on us to take every opportunity to keep our communities
                            clean and safe for all of our families and businesses.</p></div>
                    </div>
                    <div className="section-gap"/>
                </div>
            </section>
        </>

    );
};

export default About;
