import React from 'react';
import bg from "../../Assets/img/bg.jpg";
import Map from "../Includes/Map";
import {Helmet} from "react-helmet";

const contactschemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://www.megatrashremoval.com/contact"
    },
    "publisher": {
      "@type": "LocalBusiness",
      "@id": "https://www.megatrashremoval.com",
      "name": "Mega Trash Removal",
      "logo": "https://www.megatrashremoval.com/static/media/logo.9f2a0fa14bef42e9fee8.png",
      "telephone": "+1 (818) 773-7599",
      "email": "sublimeproinc@gmail.com",
      "description": "Get in touch with Mega Trash Removal. Visit us at 8444 Reseda Blvd, Northridge, CA, or call (818) 773-7599. Let's clean up together!",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "8444 Reseda Blvd, Northridge, CA",
        "addressLocality": "Northridge",
        "addressRegion": "CA",
        "postalCode": "91324"
      }
    }
  };

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact | Mega Trash Removal: Best Services for Junk Removal</title>
                <meta name="description" content="Get in touch with Mega Trash Removal. Visit us at 8444 Reseda Blvd, Northridge, CA, or call (818) 773-7599. Let's clean up together!" />
                <link rel="canonical" href="https://www.megatrashremoval.com/contact" />
                <script type="application/ld+json">
                    {JSON.stringify(contactschemaMarkup)}
                </script>
                <meta property="og:url" content="https://www.megatrashremoval.com/contact"/>
                <meta property="og:site_name" content="#site_name"/>
                <meta property="og:title" content="Contact | Mega Trash Removal: Best Services for Junk Removal"/>
                <meta property="og:description" content="Get in touch with Mega Trash Removal. Visit us at 8444 Reseda Blvd, Northridge, CA, or call (818) 773-7599. Let's clean up together!"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="Contact | Mega Trash Removal: Best Services for Junk Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="314"/>
                <meta property="og:image:alt" content="Contact | Mega Trash Removal: Best Services for Junk Removal"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="400"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:image:alt" content="Contact | Mega Trash Removal: Best Services for Junk Removal"/>

                <meta name="twitter:card" content="sumary"/>
                <meta name="twitter:site" content="#site_twitter"/>
                <meta name="twitter:creator" content="#creator_twitter"/>

            </Helmet>
            <section>
                <div className="container-fluid g-0">
                    <div className="page-banner">
                        <img src={bg} className="img-fluid" alt="About Us"/>
                        <div className="overly"/>
                        <div className="content">
                            <h1 className="display-1 text-white text-uppercase">Contact</h1>
                        </div>
                    </div>
                </div>
                <div className="container section-gap">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card my-card h-100">
                                <div className="card-header">
                                    <h4 className="text-uppercase">Get in touch</h4>
                                </div>
                                <div className="card-body px-5 py-3">
                                    <form>
                                        <label>Name
                                            <input type="name" className="form-control" placeholder="Enter Your Full Name"/>
                                        </label>
                                        <label>Subject
                                            <input type="name" className="form-control" placeholder="Enter Your Full Name"/>
                                        </label>
                                        <label>Message
                                            <textarea className="form-control" placeholder="Enter Your Message"/>
                                        </label>
                                        <div className="d-grid mt-3">
                                            <button className="btn btn-success">SEND MESSAGE</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card my-card h-100">
                                <div className="card-header"><h4 className="text-uppercase">Contact us</h4></div>
                                <div className="card-body px-5 py-3">
                                    <div className="about-contact mt-5">
                                        <div className="row my-3">
                                            <div className="col-2"><i className="fa fa-map-marker"/></div>
                                            <div className="col-10"><p>8444 Reseda Blvd, Northridge, CA</p></div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-2"><i className="fa fa-phone"/></div>
                                            <div className="col-10"><a href="tel:8187737599">(818) 773-7599</a></div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-2"><i className="fa fa-paper-plane"/></div>
                                            <div className="col-10"><a
                                                href="mailto:sublimeproinc@gmail.com">sublimeproinc@gmail.com</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Map/>
        </>

    );
};

export default Contact;
