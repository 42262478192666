import React, {useEffect} from 'react';

import PlayStore from './../../Assets/img/play.png'
import AppStore from './../../Assets/img/app.png'
import {Link, useHistory} from "react-router-dom";
import alanBtn from "@alan-ai/alan-sdk-web";

const Footer = () => {
    const alanKey= 'ba7f064a2c92a1b54ca335b918d068a22e956eca572e1d8b807a3e2338fdd0dc/stage'

    useEffect(()=>{
        alanBtn({
            key: alanKey,
            right: '60px',
            onCommand:(commandData)=> {
                if (commandData.command =='testCommand'){

                }
            }
        })
    }, [])

    return (
        <section>
            <div className="container-fluid footer-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3"><h4>About Us</h4><p className="lead">In fact, our employees live in
                            the neighborhoods where we provide service, Call us at (818) 773-7599, so you can count on
                            us to take every opportunity to keep our communities clean and safe for all of our families
                            and businesses.</p>
                            <div className="footer-social"><a
                                href="https://www.facebook.com/MegaBusinessUSAcom-294802020626441" target="_blank"><i
    className="fab fa-facebook-square"/></a><a href="https://twitter.com/MegaBusinessUSA"
                                                                              target="_blank"><i
    className="fab fa-twitter-square"/></a><a
                                href="https://www.instagram.com/megabusinessusa" target="_blank"><i
    className="fab fa-instagram"/></a></div>
                        </div>
                        <div className="col-md-3"><h4>Services</h4>
                            <div className="footer-menu">
                                <a href="/general-clean-up"><i className="fa fa-check"/> General Clean Up</a>
                                <a href="/construction-trash-removal"><i className="fa fa-check"/> Construction Trash Removal</a>
                                <a href="/yard-clean-up"><i className="fa fa-check"/> Yard Clean Up</a>
                                <a href="/hills-clean-up"><i className="fa fa-check"/> Hills Clean Up</a>
                                <a href="/residential-trash-removal"><i className="fa fa-check"/> Residential Trash Removal</a>
                                <a href="/commercial-trash-removal"><i  className="fa fa-check"/> Commercial Trash Removal</a>
                                <a href="/demolition-hauling-service"><i className="fa fa-check"/> Demolition Hauling Service</a>
                                <a href="/"><i className="fa fa-check"/> Privacy Policy</a>
                            </div>
                        </div>
                        <div className="col-md-3"><h4>Contact</h4>
                            <div className="footer-contact">
                                <div className="row my-3">
                                    <div className="col-2"><i className="fa fa-map-marker"/></div>
                                    <div className="col-10"><p>8444 Reseda Blvd, Northridge, CA</p></div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-2"><i className="fa fa-phone"/></div>
                                    <div className="col-10"><a href="callto:8187737599">(818) 773-7599</a></div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-2"><i className="fa fa-paper-plane"/></div>
                                    <div className="col-10"><a
                                        href="mailto:sublimeproinc@gmail.com">sublimeproinc@gmail.com</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"><h4>Download APPS</h4>
                            <div className="apps-link">
                                <a  href="https://play.google.com/store/apps/details?id=com.aicl.megatrashremoval.android" target={'_blank'}>
                                    <img src={PlayStore}  alt="Download App"/>
                                </a>
                                <a
                                href="https://apps.apple.com/pk/app/mega-trash-removal/id1525891440" target={'_blank'}>
                                    <img src={AppStore}  alt="Download App"/>
                                    </a>
                                </div>
                            <div className="newsletter">
                                <div className="input-group">
                                    <input type="email" className="form-control"
                                                                    placeholder="Subscribe"/>
                                    <button className="btn btn-success input-group-text">
                                        <i className="fas fa-paper-plane"/></button></div>
                            </div>
                            <div className="privacy-link text-start">
                                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid footer-credit">
                <div className="container"><p>Copyright © Sublime Productions Inc, <a href="https://megahotmop.com"
                                                                                      target="_blank">Mega Hot
                    Mop</a>, <a href="https://megatrashremoval.com" target="_blank">Mega Hot Mop Inc.</a>, <a
                    href="https://www.megabusinessusa.com" target="_blank">MegabusinessUSA.com</a>, <a
                    href="https://megatrashremoval.com" target="_blank">Sublimelist.com</a>, Power Plumbing Drain
                    Cleaning,Mega Trash Removal 2017 - 2021.</p><p>All rights reserved. Developed by: Sublime
                    Productions Inc</p></div>
            </div>
        </section>
    );
};

export default Footer;
