import React from 'react';
import {GoogleMap, useJsApiLoader} from "@react-google-maps/api";

const containerStyle = {
    width: '100%',
    height: '400px',
    marginTop: '15px'

};

const center = {
    lat: -3.745,
    lng: -38.523
};


const Map = () => {


    return (
        <section>


            <div className="container-fluid section-gap">
                <iframe
                    src="https://www.google.com/maps/d/embed?mid=1z_VJ1n9UrsBc8C-jlRbGtKfPtNqLmnkm&ehbc=2E312F"
                    width="100%" height="400" frameBorder="0" allowFullScreen="" aria-hidden="false"
                    tabIndex="0"/>
            </div>
        </section>
    );
};

export default Map;
