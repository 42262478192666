import React from 'react';
import bg from "../../Assets/img/bg.jpg";
import about from "../../Assets/img/demolation.jpg";
import {Helmet} from "react-helmet";

const demolitionschemaMarkup ={
    "@context": "https://schema.org",
    "@type": "ServicePage",
    "name": "DEMOLITION & HAULING SERVICES",
    "Image": "https://www.megatrashremoval.com/static/media/demolation.141307bcb3f8b3cbb984.jpg",
    "@id": "https://www.megatrashremoval.com/demolition-hauling-service",
    "url": "https://www.megatrashremoval.com/",
    "telephone": "(818) 773-7599",
    "priceRange": "$$$$",
    "address": {
      "@type": "8444 Reseda Blvd, Northridge, CA",
      "streetAddress": "8444 Reseda Blvd",
      "addressLocality": "Los Angeles County & San Fernando Valley",
      "addressRegion": "CA",
      "postalCode": "91324",
      "addressCountry": "USA"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 34.22406876637411,
      "longitude": -118.53574448656515
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Sunday"
      ],
      "opens": "07:00",
      "closes": "22:00"
    },
    "sameAs": [
      "https://twitter.com/MegaBusinessUSA",
    ] 
  };  

const DemolitionHaulingService = () => {
    return (
        <>
            <Helmet>
                <title>Mega Trash Removal | Demolition Hauling Services</title>
                <meta name="description" content="Efficient Demolition & Hauling services in LA County & San Fernando Valley. Trust Mega Trash Removal for expert demolition solutions. Call us!" />
                <link rel="canonical" href="https://www.megatrashremoval.com/demolition-hauling-service" />
                <script type="application/ld+json">
                    {JSON.stringify(demolitionschemaMarkup)}
                </script>
                <meta property="og:url" content="https://www.megatrashremoval.com/demolition-hauling-service"/>
                <meta property="og:site_name" content="#site_name"/>
                <meta property="og:title" content="Mega Trash Removal | Demolition Hauling Services"/>
                <meta property="og:description" content="Efficient Demolition & Hauling services in LA County & San Fernando Valley. Trust Mega Trash Removal for expert demolition solutions. Call us!"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="Mega Trash Removal | Demolition Hauling Services"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="314"/>
                <meta property="og:image:alt" content="Mega Trash Removal | Demolition Hauling Services"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/bg.e5f09d164567e89f03e9.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="400"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:image:alt" content="Mega Trash Removal | Demolition Hauling Services"/>

                <meta name="twitter:card" content="sumary"/>
                <meta name="twitter:site" content="#site_twitter"/>
                <meta name="twitter:creator" content="#creator_twitter"/>

            </Helmet>
            <section>
                <div className="container-fluid g-0">
                    <div className="page-banner">
                        <img src={bg} className="img-fluid" alt="About Us"/>
                        <div className="overly"/>
                        <div className="content">
                            <h1 className="display-1 text-white text-uppercase">DEMOLITION & HAULING SERVICES</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row section-gap">
                        <div className="col-md-6">
                            <img src={about} className="img-thumbnail" alt="About US"/>
                        </div>
                        <div className="col-md-6">
                            <h6 className="text-center text-uppercase">WELCOME TO MEGA TRASH REMOVAL</h6>
                            <h5 className="text-center text-uppercase theme-color">About Mega Trash Removal</h5>
                            <p className="lead py-5">Awesome! the best company that lets you clean and remove trash and
                                rubbish anywhere LA county you want.
                            </p>
                            <div className="row">
                                <div className="col-4"><h2 className="theme-color display-3">13</h2><p
                                    className="lead">YEARS OF EXPERIENCED</p></div>
                                <div className="col-4"><h2 className="theme-color display-3">2,342</h2><p
                                    className="lead">HAPPY CUSTOMERS</p></div>
                                <div className="col-4"><h2 className="theme-color display-3">30</h2><p
                                    className="lead">AWARD WINNING </p></div>
                            </div>
                        </div>
                    </div>
                    <div className="row section-gap justify-content-center">
                        <div className="col-md-12 text-start">
                            <h4 className="text-uppercase mb-3">DEMOLITION & HAULING SERVICES</h4>
                            <p className="lead">
                                Mega Trash Removal does Demolation & Hauling services around Los Angeles County & San Fernando Valley. Call us at (818) 773-7599.                        </p>
                        </div>
                    </div>
                    <div className="section-gap"/>
                </div>
            </section>
        </>

    );
};

export default DemolitionHaulingService;
