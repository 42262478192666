import React from 'react';
import img from './../../Assets/img/blog.jpg'

const Blog = () => {

    const blogs = [
        {
            'date': '24 February 2020',
            img: img,
            title: 'Construction Trash Removal',
            text: 'Mega Trash Removal does Construction Trash Removal services around Los Angeles County San Fernando Valley.'
        },
        {
            'date': '24 February 2020',
            img: img,
            title: 'General Clean Up',
            text: 'Awesome! the best company that lets you clean and remove trash and rubbish anywhere LA county you want.'
        },
        {
            'date': '24 February 2020',
            img: img,
            title: 'Commercial Trash Removal',
            text: 'Mega Trash Removal does Commercial Trash Removal services around Los Angeles County & San Fernando Valley.'
        }
    ]

    return (
        <section>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12 py-5"><h2
                        className="text-center text-uppercase display-4">NEWS BLOG</h2><h3
                        className="text-center text-uppercase display-6 theme-color">Latest news from our blog</h3>
                    </div>
                </div>
                <div className="row">
                    {blogs.map((blog, index)=>(
                        <div className="col-md-4">
                            <div className="home-blog">
                                <img src={blog.img} className="img-thumbnail"
                                     alt="blog"/>
                                <p className="my-2"><small>{blog.date}</small>
                                </p>
                                <a href="/construction-trash-removal"><h4>{blog.title} </h4></a>
                                <p>{blog.text}
                                    <a href="/construction-trash-removal">
                                        <button className="btn btn-sm btn-outline-success ms-2">Read More</button>
                                    </a>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Blog;
