import React from 'react';
import  logo from './../../Assets/img/logo.png'
import {Link} from "react-router-dom";

const Logo = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <Link to="/"><img src={logo} className="img-fluid logo" alt="Mega Trash Removal Logo"/></Link>
                    </div>
                    <div className="col-md-4">
                        <div className="logo-area-content">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-end"><h6>ADDRESS</h6>
                                    <address>8444 Reseda Blvd, Northridge, CA</address>
                                </div>
                                <div className="flex-shrink-0 ms-3"><i className="fas fa-map-marked-alt fa-2x"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="logo-area-content">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-end"><h6>CALL US</h6><a href="callto:818773-7599">(818)
                                    773-7599</a></div>
                                <div className="flex-shrink-0 ms-3"><i className="fas fa-phone-square fa-2x"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Logo;
