import React from 'react';

const Service = () => {
    return (
        <section>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12"><h2 className="display-1 text-center text-uppercase">We offer
                        Services</h2></div>
                </div>
                <div className="row section-gap">
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fas fa-trash"></i></div>
                            </div>
                            <h4>General Clean Up</h4><p className="lead">Awesome! the best company that lets you clean
                            and remove trash and rubbish anywhere LA county you want.</p>
                            <div className="link text-end"><a href="/general-clean-up">Read More ...</a></div>
                        </div>
                    </div>
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fa fa-building"></i></div>
                            </div>
                            <h4>Construction Trash Removal</h4><p className="lead">Mega Trash Removal does Construction
                            Trash Removal services around Los Angeles County &amp; San Fernando Valley.</p>
                            <div className="link text-end"><a href="/construction-trash-removal">Read More ...</a></div>
                        </div>
                    </div>
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fa fa-leaf"></i></div>
                            </div>
                            <h4>Yard Clean Up</h4><p className="lead">Mega Trash Removal does Yards Clean Up services
                            around Los Angeles County &amp; San Fernando Valley.</p>
                            <div className="link text-end"><a href="/yard-clean-up">Read More ...</a></div>
                        </div>
                    </div>
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fas fa-mountain"></i></div>
                            </div>
                            <h4>Hills Clean Up</h4><p className="lead">Mega Trash Removal does Hills Clean Up services
                            around Los Angeles County &amp; San Fernando Valley.</p>
                            <div className="link text-end"><a href="/hills-clean-up">Read More ...</a></div>
                        </div>
                    </div>
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fa fa-home"></i></div>
                            </div>
                            <h4>Residential Trash Removal</h4><p className="lead">Mega Season Removal does Residential
                            Trash Removal services around Los Angeles County &amp; San Fernando Valley.</p>
                            <div className="link text-end"><a href="/residential-trash-removal">Read More ...</a></div>
                        </div>
                    </div>
                    <div className="col-md-4 my-5">
                        <div className="service-card h-100">
                            <div className="icon">
                                <div className="icon-inner"><i className="fa fa-briefcase"></i></div>
                            </div>
                            <h4>Commercial Trash Removal</h4><p className="lead">Mega Trash Removal does Commercial
                            Trash Removal services around Los Angeles County &amp; San Fernando Valley.</p>
                            <div className="link text-end"><a href="/commercial-trash-removal">Read More ...</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Service;
