import React from 'react';
import RegistrationForm from "../Includes/RegistrationForm";
import Service from "../Includes/Service";
import Experts from "../Includes/Experts";
import HomeAbout from "../Includes/HomeAbout";
import Testimonial from "../Includes/Testimonial";
import Blog from "../Includes/Blog";
import Map from "../Includes/Map";
import Slider from "../Includes/Slider";
import {Helmet} from "react-helmet";

const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Mega Trash Removal",
    "image": "https://www.megatrashremoval.com/static/media/logo.9f2a0fa14bef42e9fee8.png",
    "@id": "https://www.megatrashremoval.com/#logo",
    "url": "https://www.megatrashremoval.com/",
    "telephone": "(818) 773-7599",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "8444 Reseda Blvd, Northridge, CA",
      "addressLocality": "Northridge",
      "addressRegion": "CA",
      "postalCode": "91324",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 34.2239357,
      "longitude": -118.5357123
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "07:00",
      "closes": "22:00"
    },
    "sameAs": [
      "https://twitter.com/MegaBusinessUSA",
      "https://www.instagram.com/megabusinessusa"
    ] 
  };


const Home = () => {
    return (
        <>
            <Helmet>
                <title>Mega Trash Removal: Best Junk Removal Services</title>
                <meta name="description" content="Welcome to Mega Trash Removal! LA's premier service for cleaning & removing trash. 13 years of experience. Call now for a cleaner tomorrow!" />
                <link rel="canonical" href="https://www.megatrashremoval.com/" />
                <script type="application/ld+json">
                    {JSON.stringify(schemaMarkup)}
                </script>
                <meta property="og:url" content="https://www.megatrashremoval.com/"/>
                <meta property="og:site_name" content="#site_name"/>
                <meta property="og:title" content="Mega Trash Removal: The Best Junk Removal Services"/>
                <meta property="og:description" content="Welcome to Mega Trash Removal! LA's premier service for cleaning & removing trash. 13 years of experience. Call now for a cleaner tomorrow!"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/1.d3bf685ded078d772f67.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="Mega Trash Removal: The Best Junk Removal Services"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/1.d3bf685ded078d772f67.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="314"/>
                <meta property="og:image:alt" content="Mega Trash Removal: The Best Junk Removal Services"/>

                <meta property="og:image" content="https://www.megatrashremoval.com/static/media/1.d3bf685ded078d772f67.jpg"/>
                <meta property="og:image:type" content="image/png"/>
                <meta property="og:image:width" content="400"/>
                <meta property="og:image:height" content="400"/>
                <meta property="og:image:alt" content="Mega Trash Removal: The Best Junk Removal Services"/>

                <meta name="twitter:card" content="sumary"/>
                <meta name="twitter:site" content="#site_twitter"/>
                <meta name="twitter:creator" content="#creator_twitter"/>

                
            </Helmet>
            <Slider/>
            <RegistrationForm/>
            <Service/>
            <Experts/>
            <HomeAbout/>
            <Testimonial/>
            <Blog/>
            <Map/>
            
            
        </>
    );
};

export default Home;
