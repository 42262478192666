import React, {useEffect, useState} from "react";
import {Redirect, Route } from "react-router-dom";
import axios from "axios";
import BASEURL from "./Constant";

const Auth = ({ component : Component, ...rest}) => {
    const [auth, setAuth] = useState(false);
    useEffect(()=>{
        const token = localStorage.user_token
        if (token){
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        let url = window.location.pathname
        if (url=='/profile'){
            axios.post(BASEURL+'/api/client/check-auth').then(res=>{
                if (res.data.msg == true){
                    setAuth(res.data.msg)
                }else {
                    localStorage.removeItem('user_token')
                    localStorage.removeItem('user_name')
                    localStorage.removeItem('user_email')
                }
            })
        }

    },[])

    const token = localStorage.user_token
    return(
        <Route
            {...rest}
            render={props=>
                token != undefined ? (
                    <Component {...props} />
                ):(
                    <Redirect
                        to={{
                            pathname:"/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    )
}
export default Auth