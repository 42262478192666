import './Assets/css/style.css'
import  '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import  '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import Home from "./Component/Pages/Home";
import TopBar from "./Component/Includes/TopBar";
import Logo from "./Component/Includes/Logo";
import Menu from "./Component/Includes/Menu";
import Footer from "./Component/Includes/Footer";
import About from "./Component/Pages/About";
import Contact from "./Component/Pages/Contact";
import Blog from "./Component/Includes/Blog";
import Blogs from "./Component/Pages/Blogs";
import GeneralCleanUp from "./Component/Pages/GeneralCleanUp";
import ConstructionTrashRemoval from "./Component/Pages/ConstructionTrashRemoval";
import YardCleanUp from "./Component/Pages/YardCleanUp";
import HillsCleanUp from "./Component/Pages/HillsCleanUp";
import ResidentialTrashRemoval from "./Component/Pages/ResidentialTrashRemoval";
import CommercialTrashRemoval from "./Component/Pages/CommercialTrashRemoval";
import DemolitionHaulingService from "./Component/Pages/DemolitionHaulingService";
import ScrollToTop from "react-scroll-to-top";
import Auth from "./Auth";
import Profile from "./Component/ClientArea/Profile";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy";


function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <TopBar/>
                <Logo/>
                <Menu/>
                    <Route exact path={'/'} component={Home}/>
                    <Route  path={'/about'} component={About}/>
                    <Route  path={'/contact'} component={Contact}/>
                    <Route  path={'/blog'} component={Blogs}/>
                    <Route  path={'/privacy-policy'} component={PrivacyPolicy}/>
                    <Route  path={'/general-clean-up'} component={GeneralCleanUp}/>
                    <Route  path={'/construction-trash-removal'} component={ConstructionTrashRemoval}/>
                    <Route  path={'/yard-clean-up'} component={YardCleanUp}/>
                    <Route  path={'/hills-clean-up'} component={HillsCleanUp}/>
                    <Route  path={'/residential-trash-removal'} component={ResidentialTrashRemoval}/>
                    <Route  path={'/commercial-trash-removal'} component={CommercialTrashRemoval}/>
                    <Route  path={'/demolition-hauling-service'} component={DemolitionHaulingService}/>

                    <Auth exact path={'/client-area'} component={Profile} />

                <Footer/>
            </BrowserRouter>
            <ScrollToTop
                smooth
                top={300}
                color={'white'}
            />
        </div>
    );
}

export default App;
