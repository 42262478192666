import React, {useEffect, useState} from 'react';
import BASEURL from './../../Constant'
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import $ from 'jquery'

const RegistrationForm = () => {

    const [service, setService] = useState([])
    const [input, setInput] = useState({})
    const [error, setError] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getService = () => {
        console.log('work working')
        axios.get(BASEURL + '/api/get-service-list').then(res => {
            setService(res.data)
        })
    }
    const handleInput = (e) => {
        setError([])

        if (e.target.name == 'sign_up' ){
            if (e.target.checked == true){
                $('#sign_up').slideDown()
                setInput(prev => ({...prev, sign_up: 1}))
            }else{
                $('#sign_up').slideUp()
                setInput(prev => ({...prev, sign_up: 0}))
            }
        }else{
            setInput(prev => ({...prev, [e.target.name]: e.target.value}))
        }
    }

    const handleImage = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setInput(prev => ({...prev, photo: reader.result}))
        }
        reader.readAsDataURL(file)
    }
    const handleOrder = () => {
        setIsLoading(true)
        setError([])
        axios.post(BASEURL + '/api/order', input).then(res => {
            toast.success(res.data.msg)
            setIsLoading(false)
            $('#reset_booking').trigger('click')
            setInput({})
        }).catch(errors => {
            if (errors.response.status == 422) {
                setError([])
                setError(errors.response.data.errors)
                setIsLoading(false)
                toast.warn('Something Wrong. Please Fix it')
            }
        })
    }

    const handlePasswordShow = () => {
        let element = $('#password')
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }
    useEffect(() => {
        getService()
        navigator.geolocation.getCurrentPosition(function (position) {
            setInput(prev => ({...prev, longitude: position.coords.longitude}))
            setInput(prev => ({...prev, latitude: position.coords.latitude}))
        });
        setInput(prev => ({...prev, service_id: 1}))
    }, [])

    return (
        <section>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card my-card h-100">
                            <div className="card-header"><h4>Booking a Service</h4></div>
                            <div className="card-body px-5 py-3">
                                <form>
                                    <label>Name
                                        <input
                                            onChange={handleInput}
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Your Full Name"
                                            name="name"
                                        />
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'name' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <label>Phone
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Your Phone Number"
                                            name="phone"
                                            onChange={handleInput}
                                        />
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'phone' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <br/>
                                    <label>Select Service
                                        <select

                                            onChange={handleInput}
                                            // onInputChange={handleSelect}
                                            value={input.service_id}
                                            name={'service_id'}
                                            className={'form-select'}
                                        >
                                            {service.map((serv, index) => (
                                                <option key={index} value={serv.value}>{serv.label}</option>
                                            ))}
                                        </select>
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'service_id' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <div className="row">
                                        <div className="col-6">
                                            <label>Select Date
                                                <input
                                                    type={'date'}
                                                    className={'form-control'}
                                                    onChange={handleInput}
                                                    name={'date'}
                                                />
                                                {Object.entries(error).map(([key, val]) => (
                                                    <small
                                                        className="text-danger validation-error-message"
                                                        key={key}>{key == 'date' ? val : ''}
                                                    </small>
                                                ))}
                                            </label>
                                        </div>
                                        <div className="col-6">
                                            <label>Select Time
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="time"
                                                    onChange={handleInput}
                                                />
                                                {Object.entries(error).map(([key, val]) => (
                                                    <small
                                                        className="text-danger validation-error-message"
                                                        key={key}>{key == 'time' ? val : ''}
                                                    </small>
                                                ))}
                                            </label>
                                        </div>
                                    </div>
                                    <label>Email
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter Your Email Address"
                                            name="email"
                                            onChange={handleInput}
                                        />
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'email' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <label>Address
                                        <textarea
                                            className="form-control"
                                            placeholder="Enter Your Address"
                                            name="address"
                                            onChange={handleInput}
                                        />
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'address' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <label>Description
                                        <textarea
                                            className="form-control"
                                            placeholder="Enter Description"
                                            name="description"
                                            onChange={handleInput}
                                        />
                                        {Object.entries(error).map(([key, val]) => (
                                            <small
                                                className="text-danger validation-error-message"
                                                key={key}>{key == 'description' ? val : ''}
                                            </small>
                                        ))}
                                    </label>
                                    <label>Photo
                                        <input
                                            className="form-control"
                                            type="file"
                                            name={'photo'}
                                            onChange={handleImage}
                                        />
                                    </label>
                                    <label>
                                        <input onInput={handleInput} name={'sign_up'} type={'checkbox'} className={'form-check-input me-2'}/>
                                        Want to signup for further order tracking?
                                    </label>

                                    <div id="sign_up" style={{display:'none'}}>
                                        <label>Password
                                            <div className="position-relative">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter Your Password"
                                                    name="password"
                                                    onChange={handleInput}
                                                    id={'password'}
                                                />
                                                <div onClick={handlePasswordShow} className="eye-button-password"><i
                                                    className={'fas fa-eye'}/></div>
                                            </div>

                                            {Object.entries(error).map(([key, val]) => (
                                                <small
                                                    className="text-danger validation-error-message"
                                                    key={key}>{key == 'password' ? val : ''}
                                                </small>
                                            ))}
                                        </label>

                                    </div>

                                    <div className="d-grid mt-3">
                                        <button id={'reset_booking'} className={'d-none'} type={"reset"}/>
                                        <button type={'button'} onClick={handleOrder} className="btn btn-success"
                                                dangerouslySetInnerHTML={{__html: isLoading ? ' <div class="spinner-border spinner-border-sm"></div>' : 'BOOK SERVICE'}}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card my-card h-100">
                            <div className="card-header"><h4>Register As A SerVice Provider</h4></div>
                            <div className="card-body">
                                <form>
                                    <label>Name
                                        <input type="name" className="form-control" placeholder="Enter Your Full Name"/>
                                    </label>
                                    <label>Email
                                        <input type="email" className="form-control"
                                               placeholder="Enter Your Email Address"/>
                                    </label>
                                    <label>Phone
                                        <input type="number" className="form-control"
                                               placeholder="Enter Your Phone Number"/>
                                    </label>
                                    <label>Address
                                        <textarea className="form-control" placeholder="Enter Your Address"/>
                                    </label>
                                    <label>Password
                                        <input type="password" className="form-control"
                                               placeholder="Enter Your Password"/>
                                    </label>
                                    <label>Confirm Password
                                        <input type="password" className="form-control" placeholder="Confirm Password"/>
                                    </label>
                                    <div className="d-grid mt-3">
                                        <button className="btn btn-info text-uppercase">Download W9 Form</button>
                                    </div>
                                    <label>
                                        Upload W9 Form<input className="form-control" type="file"/>
                                    </label>
                                    <div className="d-grid mt-3">
                                        <button className="btn btn-success">SEND MESSAGE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegistrationForm;
