import React from 'react';
import Slider from "react-slick";

import './../../../node_modules/slick-carousel/slick/slick-theme.css'
import './../../../node_modules/slick-carousel/slick/slick.css'

import Img1  from './../../Assets/img/service/general-clean-up.jpg'
import Img2  from './../../Assets/img/service/commarcial.jpg'
import Img3  from './../../Assets/img/service/construction.jpg'
import Img4  from './../../Assets/img/service/hill.jpg'
import Img5 from './../../Assets/img/service/yeard.jpg'
import Img6 from './../../Assets/img/service/residencial.jpg'

const Experts = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    const slides = [
        {img : Img1, title : 'GENERAL CLEAN UP'},
        {img : Img2, title : 'CONSTRUCTION TRASH REMOVAL'},
        {img : Img3, title : 'YARD CLEAN UP'},
        {img : Img4, title : 'HILLS CLEAN UP'},
        {img : Img5, title : 'RESIDENTIAL TRASH REMOVAL'},
        {img : Img6, title : 'COMMERCIAL TRASH REMOVAL'},
    ]

    return (
        <section>
            <div className="container section-gap">
                <div className="row">
                    <div className="col-md-12"><h2 className="text-center display-1 text-uppercase">Our Expertise</h2>
                    </div>
                </div>
                <div className="row section-gap">
                    <div className="col-md-12">
                        <Slider {...settings}>
                            {slides.map((slide, index)=>(
                                <div key={index}>
                                    <div className="expertise-slider-item">
                                        <img src={slide.img} className="img-fluid" alt="slider"/>
                                    </div>
                                    <div className="slider-content">
                                        <h4>{slide.title}</h4>
                                    </div>
                                </div>
                            ))}


                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experts;
