import React, {useState} from 'react';
import Slider1 from './../../Assets/img/slider/1.jpg'
import Slider2 from './../../Assets/img/slider/2.jpg'
import Slider3 from './../../Assets/img/slider/3.jpg'

const Slider = () => {
    const [slider] = useState([
        {img: Slider1},
        {img: Slider2},
        {img: Slider3},
    ])
    return (
        <>
            <section className="slider">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {slider.map((slider, index) => (
                            <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index}>
                                <img src={slider.img} className="d-block w-100 banner-image"
                                     alt="..."/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h3 className="text-danger text-center">WELCOME </h3>
                                    <h1 className="text-white display-4 banner-h1">MEGA TRASH REMOVAL</h1>
                                </div>
                            </div>
                        ))}

                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"/>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="banner-schedule">
                                <div className="row">
                                    <div className="col-md-6 border-end"><h5>Business Hours</h5><h6>OPENING DAYS:</h6><p>Monday
                                        – Friday : <span>7am to 10 pm</span></p><p>Saturday :<span>7am to 10 pm</span></p></div>
                                    <div className="col-md-6"><h5>FOR EMERGENCY CASES</h5><h2><a href="callto:818773-7599">(818)
                                        773-7599</a></h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
)
    ;
};

export default Slider;
