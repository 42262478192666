import React from 'react';

import Slider from "react-slick";

import './../../../node_modules/slick-carousel/slick/slick-theme.css'
import './../../../node_modules/slick-carousel/slick/slick.css'

const Testimonial = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    const testmonials = [
        {speech : 'Best Service for trash removal. Highly Recommended', said:'John Baker, CA'},
        {speech : 'Service is really appreciable. Good service and reasonable.Thanks.', said:'JPeter, CA'},
        {speech : 'Awesome! the best company that lets you clean and remove trash and rubbish anywhere LA county you want.', said:'Jane Doe, Frankfurt'}
    ]

    return (
        <section>
            <div className="container-fluid testimonial section-gap">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-12 py-5"><h2
                            className="text-center text-uppercase display-4">TESTIMONIES</h2><h3
                            className="text-center text-uppercase display-6 theme-color">Happy
                            Clients &amp; Feedbacks</h3></div>
                    </div>
                    <div className="row py-5">
                        <div className="col-md-12 h-100">
                            <Slider {...settings}>
                                {testmonials.map((testmonial, index)=>(
                                    <div key={index}>
                                        <div className="testimonial-content-card">
                                            <i className="fas fa-quote-left"/>
                                            <figure>
                                                <blockquote className="blockquote"><p>{testmonial.speech}</p>
                                                </blockquote>
                                                <figcaption className="blockquote-footer">{testmonial.said}</figcaption>
                                            </figure>
                                            <i className="fas fa-quote-right"/>
                                        </div>
                                    </div>
                                ))}
                            </Slider>



                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
