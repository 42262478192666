import React from 'react';
import {Link} from "react-router-dom";

const Menu = () => {
    return (
        <section>
            <div className="container-fluid nav-container">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-6">
                            <div className="navigation">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li className="sub-nav-parent"><span>Services</span>
                                        <ul>
                                            <li><Link to="/general-clean-up">General Clean Up</Link></li>
                                            <li><Link to="/construction-trash-removal">Construction Trash Removal</Link>
                                            </li>
                                            <li><Link to="/yard-clean-up">Yard Clean Up</Link></li>
                                            <li><Link to="/hills-clean-up">Hills Clean Up</Link></li>
                                            <li><Link to="/residential-trash-removal">Residential Trash Removal</Link></li>
                                            <li><Link to="/commercial-trash-removal">Commercial Trash Removal</Link></li>
                                            <li><Link to="/demolition-hauling-service">Demolition  Hauling Service</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Menu;
