import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";

import $ from 'jquery'
import {Modal} from "react-bootstrap";
import axios from "axios";
import BASEURL from "../../Constant";

const TopBar = () => {
    let history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputData, setInputData] = useState({});
    const [errMsg, setErrMsg] = useState([]);

    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInputData(prev => ({...prev, [name]: value}))
    }

    const handleMiniNav = () => {
        if (localStorage.user_token == 'undefined') {
            let element = $('#mini_nav')
            let display = element.css('display')
            if (display == 'none') {
                element.slideDown()
            } else {
                element.slideUp()
            }
        } else {
            setIsModalOpen(true)
        }
    }
    const login = (e) => {
        e.preventDefault()
        axios.post(BASEURL + '/api/client/login', inputData).then(res => {
            localStorage.user_name = res.data.name
            localStorage.user_email = res.data.email
            localStorage.user_token = res.data.token
            setIsModalOpen(false)
            history.push('/client-area')
            window.location.reload()
        }).catch(errors => {
            if (errors.response.status == 422) {
                setErrMsg(errors.response.data.errors)
            }
        })
    }

    return (
        <section>
            <div className="container-fluid top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-start">
                            <div className="top-bar-link"><a
                                href="mailto:sublimeproinc@gmail.com">sublimeproinc@gmail.com</a><a
                                href="callto:818773-7599">(818) 773-7599</a></div>
                        </div>
                        <div className="col-md-6 text-end">
                            <div className="top-bar-social">
                                <a
                                    href="https://www.facebook.com/MegaTrashRemovalServices"
                                    target="_blank">
                                    <i className="fab fa-facebook-square"/>
                                </a>
                                <a href="https://twitter.com/MTRemoval"
                                   target="_blank">
                                    <i className="fab fa-twitter-square"/></a>
                                <a href="https://www.instagram.com/megabusinessusa" target="_blank"><i
                                    className="fab fa-instagram"/>
                                </a>
                                <a href="https://www.youtube.com/@MegaHotMop" target="_blank"><i
                                    className="fab fa-youtube"/>
                                </a>
                                <span onClick={handleMiniNav}> <i className="fas fa-user"/></span>
                                <div id={'mini_nav'} className="mini-nav" style={{display: 'none'}}>
                                    <Link to={'/'}>Profile</Link>
                                    <Link to={'/'}>Change Password</Link>
                                    <Link to={'/'}>My Orders</Link>
                                    <Link to={'/'}>Logout</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>Login</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="login-registration-panel">
                        <form className={'px-4 py-2'}>
                            <label className={'w-100'}>
                                Email/Phone
                                <input onChange={handleInput} type={'text'} name={'email'}
                                       className={'form-control mt-2'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'email' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </label>
                            <label className={'w-100 mt-2'}>
                                Password
                                <input onChange={handleInput} type={'password'} name={'password'}
                                       className={'form-control mt-2'}/>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <p key={key}>
                                        <small
                                            className="text-danger validation-error-message">
                                            {key == 'password' ? val : ''}
                                        </small>
                                    </p>
                                ))}
                            </label>
                            <div className="row justify-content-center">
                                <div className="col-6">
                                    <div className="d-grid">
                                        <button onClick={login} className={'btn btn-outline-info mt-3'}>Login</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>


        </section>
    );
};

export default TopBar;
