import React from 'react';

const Profile = () => {
    return (
        <>
            <h1>Hello</h1>
        </>
    );
};

export default Profile;
